<script setup>
import { WdsAppBanner } from '@wds/components';

// ### PROPS
const props = defineProps({
  page: {
    type: String,
    required: true,
  },
  linkPaths: {
    type: Object,
    required: true,
  },
  linkParams: {
    type: String,
    default: 'search',
  },
});

// ### CONSTANTS
const APP_BANNER_LINK = 'https://hostelworld.onelink.me/IdiK/vnn7oxyn';

// ### COMPOSABLES
const { $tracking } = useNuxtApp();
const { isMobileOrTablet } = useDevice();
const {
  APP_BANNER_FEAT_TOGGLE,
  dismissAppDownloadBanner,
  isAppDownloadBannerDismissed,
} = useAppDownloadBanner();

// ### STORES
const testingStore = useTestingStore();

// ### DATA
const appBannerEnabled = testingStore.hasEnabledFeature(APP_BANNER_FEAT_TOGGLE);
const appBannerVars = testingStore.getFeatureVariables(APP_BANNER_FEAT_TOGGLE);
const appBanner = ref({
  enabled: appBannerEnabled && (appBannerVars[props.page] || appBannerVars.staticpages[props.page]),
  show: false,
  vars: appBannerVars || {},
});

// ### COMPUTED
const showAppDownloadBanner = computed(() => {
  return appBanner.value.enabled && isMobileOrTablet && appBanner.value.show;
});

const appDownloadTitle = computed(() => {
  return appBanner.value.vars?.message === 'b' ? 't_APPBANNERTITLEPRICING' : 't_APPBANNERTITLESOCIAL';
});

const appDownloadSubTitle = computed(() => {
  return appBanner.value.vars?.message === 'b' ? 't_APPBANNERSUBTITLEPRICING' : 't_APPBANNERSUBTITLESOCIAL';
});

// ### METHODS
const handleAppBannerDismiss = function () {
  dismissAppDownloadBanner();
  appBanner.value.show = false;
  $tracking?.onAppDownloadBannerDismiss();
};

const handleAppBannerClick = function () {
  $tracking?.onAppDownloadBannerOpen();

  const appDeepLinkPath = props.linkPaths[appBanner.value.vars?.message] || props.linkPaths.a || APP_BANNER_LINK;
  window.location.href = `${appDeepLinkPath}?deep_link_sub10=${btoa(`hw://s/${props.linkParams}`)}`;
};

// ### MOUNTED
onMounted(() => {
  if (appBanner.value.enabled) {
    appBanner.value.show = !isAppDownloadBannerDismissed();
  }
});
</script>

<template>
  <WdsAppBanner
    v-if="showAppDownloadBanner"
    :title="$t(appDownloadTitle)"
    :subtitle="$t(appDownloadSubTitle)"
    :action-button="$t('t_APPBANNEROPEN').toUpperCase()"
    @dismiss-click="handleAppBannerDismiss"
    @banner-click="handleAppBannerClick"
  />
</template>
